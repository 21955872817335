import { render } from "./List.vue?vue&type=template&id=67f55aa5&scoped=true"
import script from "./List.vue?vue&type=script&lang=js"
export * from "./List.vue?vue&type=script&lang=js"

import "./List.vue?vue&type=style&index=0&id=67f55aa5&scoped=true&lang=css"
import "@vueform/multiselect/themes/default.css?vue&type=style&index=1&lang=css"
import "./List.vue?vue&type=style&index=2&id=67f55aa5&scoped=true&lang=css"
script.render = render
script.__scopeId = "data-v-67f55aa5"

export default script